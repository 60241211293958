<template>
  <v-alert
    v-if="fetching"
    class="rounded-lg"
    color="grey"
    outlined
    variant="text"
  >
    <v-skeleton-loader type="list-item-three-line" />
  </v-alert>
  <div v-else class="flex flex-col gap-2">
    <PublicationAlert
      v-for="(pub, i) in publication.publications"
      :key="i"
      :publication="pub"
      :publish="publish"
      :unpublish="unpublish"
    />
  </div>
</template>

<script lang="ts">
import type { PlatformProductType } from "~/layers/admin/types/common";
import PublicationAlert from "./PublicationAlert.vue";

export default defineComponent({
  props: {
    type: {
      type: String as PropType<PlatformProductType>,
      required: true,
    },
    where: { type: Object as PropType<ResourceWhereInput>, required: true },
  },
  components: { PublicationAlert },
  setup(props) {
    const {
      publication,
      fetching,
      publishing,
      unpublishing,
      publish,
      unpublish,
      fetchPublication,
    } = usePublication(props.type, props.where);

    provide("publishing", publishing);
    provide("unpublishing", unpublishing);
    provide("fetching", fetching);

    onMounted(fetchPublication);

    return {
      publication,
      publish,
      unpublish,
      fetching,
    };
  },
});
</script>
