<template>
  <div>
    <h4>{{ title }}</h4>
    <v-skeleton-loader
      v-if="loading"
      type="list-item-avatar-three-line"
      class="bg-transparent"
    />
    <v-list v-else :disabled="disabled" class="bg-transparent">
      <v-list-item
        v-for="step in steps"
        class="px-2 rounded-lg"
        :key="step.handle"
        :title="step.title"
        :subtitle="step.description"
        @click="handleClick(step)"
      >
        <template #prepend>
          <v-icon v-if="step.complete" color="green">task_alt</v-icon>
          <v-icon v-else color="grey"> panorama_fish_eye</v-icon>
        </template>
      </v-list-item>
    </v-list>
  </div>
</template>

<script lang="ts">
import type { IOnboardStep } from "../models/interfaces";

export default defineComponent({
  name: "OnboardSteps",
  props: {
    title: { type: String, default: "Steps" },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    steps: {
      type: Array as PropType<IOnboardStep[]>,
      default: () => [],
    },
  },
  methods: {
    handleClick(step: IOnboardStep) {
      this.$emit("click", step);
    },
  },
});
</script>
