import gql from "graphql-tag";
import { useApolloClient } from "@vue/apollo-composable";
import dayjs from "dayjs";
import { PublicationStatus, PublishedStatus } from "../models";
import { set } from "lodash";
import type { PlatformProductType } from "../types/common";

export interface ResourceWhereInput {
  id?: number | string;
  handle?: string;
}

export interface IPublication {
  status: PublishedStatus;
  publications: IPublicationItem[];
}

interface ISalesChannel {
  handle: string;
  name: string;
  offline: boolean;
  allowed_currencies: string[];
  // app: {
  //   handle: string
  //   name: string
  //   short_description?: string
  //   description?: string
  //   integration: string
  // }
}

export interface IPublicationItem {
  status: PublicationStatus;
  channel: ISalesChannel;
  errorMessages: string[];
  errors: {
    codes: string[];
    messages: Record<string, string[]>;
  };
  schedule?: string;
}

const queryBody = `
  status
  publications{
    status
    channel{
      handle
      name
    }
    errors
    errorMessages
    schedule
  }
`;

export const usePublication = (
  type: PlatformProductType,
  where: ResourceWhereInput
) => {
  const apollo = useApolloClient();
  const apolloClient = apollo.resolveClient();

  const fetching = ref(false);
  const publishing = ref<Record<string, boolean>>({});
  const unpublishing = ref<Record<string, boolean>>({});
  const loading = computed(
    () =>
      fetching.value ||
      Object.values(publishing.value).some((x) => x === true) ||
      Object.values(unpublishing.value).some((x) => x === true)
  );
  const publication = ref<IPublication>({
    status: PublishedStatus.PENDING,
    publications: [],
  });

  const setPublicationData = (data: any) => {
    Object.assign(publication.value, {
      status: data.status,
      publications: (data.publications || []).map((publication: any) => {
        let errors: any;
        try {
          errors = JSON.parse(publication.errors);
        } catch (error) {
          //
        }
        return {
          ...publication,
          errors,
        };
      }),
    });
  };

  const fetchPublication = () => {
    fetching.value = true;
    apolloClient
      .query({
        query: gql`
          query ($type: String!, $where: ResourceWhereInput!) {
            wsPublication(type: $type, where: $where) {
              ${queryBody}
            }
          }
        `,
        variables: {
          type,
          where,
        },
      })
      .then(({ data }) => {
        setPublicationData(data.wsPublication);
      })
      .finally(() => (fetching.value = false));
  };

  const publish = (channel: string, schedule?: Date | string) => {
    set(publishing.value, channel, true);
    apolloClient
      .mutate({
        mutation: gql`
          mutation ($type: String!, $where: ResourceWhereInput!, $channel: String!, $schedule: DateTime) {
            wsPublish(type: $type, where: $where, channel: $channel, schedule: $schedule) {
              ${queryBody}
            }
          }
        `,
        variables: {
          type,
          where,
          channel,
          schedule: schedule
            ? dayjs(schedule).format("YYYY-MM-DD h:mm:ss")
            : undefined,
        },
      })
      .then(({ data }) => {
        setPublicationData(data.wsPublish);
      })
      .finally(() => {
        set(publishing.value, channel, false);
      });
  };

  const unpublish = (channel: string) => {
    set(unpublishing.value, channel, true);
    apolloClient
      .mutate({
        mutation: gql`
          mutation ($type: String!, $where: ResourceWhereInput!, $channel: String!) {
            wsUnpublish(type: $type, where: $where, channel: $channel) {
              ${queryBody}
            }
          }
        `,
        variables: {
          type,
          where,
          channel,
        },
      })
      .then(({ data }) => {
        setPublicationData(data.wsUnpublish);
      })
      .finally(() => {
        set(unpublishing.value, channel, false);
      });
  };

  return {
    publication,
    fetching,
    publishing,
    unpublishing,
    loading,
    fetchPublication,
    publish,
    unpublish,
  };
};
